import { SYS_UIDS } from './const/config'

export default {
  apiPath: window.config.VUE_APP_API_PATH,
  imgPath: window.config.VUE_APP_IMG_PATH,
  sysUids: SYS_UIDS,
  logoPostUid: Number(window.config.VUE_APP_LOGO_POST_UID),
  worldPeacePostUid: Number(window.config.VUE_APP_WORLDPEACE_POST_UID),
  postCharsLimit: Number(window.config.VUE_APP_POST_CHAR_LIMIT),
  teamWord: window.config.VUE_APP_TEAM_WORD,
  dateTimeFormat: window.config.VUE_APP_DATETIME_FORMAT,
  minYears: Number(window.config.VUE_APP_MIN_YEARS),
  showPinned: window.config.VUE_APP_SHOW_PINNED,
  showCopyButton: window.config.VUE_APP_SHOW_COPY_BUTTON,
  popDelay: window.config.VUE_APP_POP_DELAY,
  isLiDisabled: window.config.VUE_APP_IS_LI_DISABLED,
  isFbDisabled: window.config.VUE_APP_IS_FB_DISABLED,
  blankMapCover: window.config.VUE_APP_MAP_COVER_IMAGE,
  defaultQrBg: window.config.VUE_APP_DEFAULT_QR_BG,
  signedOutQr: window.config.VUE_APP_SIGNED_OUT_QR,
  commentsPlaceholder: "Talk to each other...",
  commentsPriorityLimit: window.config.VUE_APP_COMMENTS_PRIORITY_LIMIT,
  commentsNormalLimit: window.config.VUE_APP_COMMENTS_NORMAL_LIMIT,
  favAfterSignIn: window.config.VUE_APP_FAV_AFTER_SIGN_IN,
  defaultThemeColor: window.config.VUE_APP_DEFAULT_THEME_COLOR,
  teamupAudioUrl: "https://s3.amazonaws.com/drivetag/sounds/teamup.mp3",
  inspirationPostUids: window.config.VUE_APP_INSPIRATION_POST_UIDS,
  mailtoLink: window.config.VUE_APP_REACH_OUT_MAILTO,
}